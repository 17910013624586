import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [
    'target',
    'loaderTemplate'
  ]

  displayLoader () {
    if (this.hasLoaderTemplateTarget) {
      this.targetTargets.forEach(target => {
        target.innerHTML = this.loaderTemplateTarget.innerHTML
      })
    }
  }

  replaceOuter (event) {
    this.target.outerHTML = this.getXhrFromEvent(event).response
  }

  replaceInner (event) {
    this.target.innerHTML = this.getXhrFromEvent(event).response
  }

  append (event) {
    this.target.insertAdjacentHTML('afterend', this.getXhrFromEvent(event).response)
  }

  prepend (event) {
    this.elementToReplace.insertAdjacentHTML('beforebegin', this.getXhrFromEvent(event).response)
  }

  redirectToLocation (event) {
    const location = this.getXhrFromEvent(event).getResponseHeader('location')

    if (location) {
      window.location = location
    }
  }

  getXhrFromEvent (event) {
    if (event.type === 'ajax:complete') {
      console.warn("Remote controller: stoping the propagation of ajax:complete event could stop flash messages from being displayed !")
      return event.detail[0]
    } else {
      return event.detail[2]
    }
  }

  get target () {
    return this.hasTargetTarget ? this.targetTarget : this.element
  }
}
